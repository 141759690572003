import { Theme } from '@emotion/react';
import { baseTheme, createMuiTheme } from 'components/theme/base';
import { CustomTheme } from 'components/theme/theme';

const darkCustomTheme: CustomTheme = {
    ...baseTheme,
    colors: {
        primary: {
            main: '#0C66E4',
            light: '#E6EFFC',
            dark: '#0052CC',
        },
        background: {
            light: 'rgba(32,33,36,0.059)',
            main: '#fff',
            dark: '#f6f8fc',
        },
        text: {
            light: '#666',
            main: '#202124',
            dark: '#001d35',
        },
    },
};

export const darkTheme: Theme = {
    ...createMuiTheme(darkCustomTheme),
    c: darkCustomTheme,
};
