/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import { userClient } from 'api/client';
import { ChatContent } from 'components/chatContent';
import { ShareChat } from 'components/shareChat';
import { Loading } from 'components/ui/loading';
import { GetShareRequest, GetShareResponse } from 'proto/v1alpha1/user_pb';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { WithLoadStatus } from 'utils/common';

export const SharePage = () => {
    const { id = '' } = useParams();
    const [share, setShare] = useState<WithLoadStatus<GetShareResponse.AsObject>>({ loading: true });

    useEffect(() => {
        setShare({ loading: true });
        userClient
            .getShare(new GetShareRequest().setId(id))
            .then((res) => {
                setShare({ loading: false, data: res.toObject() });
            })
            .catch((e) => {
                setShare({ loading: false, loadErr: e.message });
            });
    }, [id]);

    return (
        <Box css={styles}>
            <Box className="logo">
                <img src="/logo192.png" />
                <Typography variant="h6">章鱼AI</Typography>
            </Box>
            <Box className="title">
                <Typography sx={{ paddingBottom: '5px' }}>{share.data?.shareTitle ?? ''}</Typography>
                <Typography variant="body2" color="text.secondary">
                    {share.data?.shareUser ? `来自${share.data?.shareUser}的分享` : ''}
                </Typography>
            </Box>
            <Box className="chat">
                <Loading loaded={share.loading === false} loadedErr={share.loadErr}>
                    {share.data?.chat?.conversation && <ShareChat conv={share.data?.chat.conversation} />}
                </Loading>
            </Box>
            <Box className="bottom">
                <Link to="/">
                    <Button size="large" variant="contained">
                        立即体验章鱼AI
                    </Button>
                </Link>
            </Box>
        </Box>
    );
};

const styles = (theme: Theme) => css`
    padding: 32px 16px;
    background-color: ${theme.c.colors.background.dark};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .logo {
        height: 92px;
        img {
            width: 36px;
        }
        color: ${theme.c.colors.primary.main};
        display: flex;
        flex-direction: column;
        align-items: center;
        h6 {
            font-size: 0.985rem;
            margin-top: 8px;
        }
    }

    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 25px;
    }

    .chat {
        flex: 1;
        padding: 16px;
        background-color: ${theme.palette.background.default};
        border-radius: 10px;
        overflow: auto;
    }

    .bottom {
        height: 90px;
        padding-top: 40px;
        text-align: center;
        button {
            padding: 16px 53px;
            font-size: 1.25em;
        }
    }
`;
