/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Alert, Box, CircularProgress, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export interface LoadingProps {
    loaded?: boolean;
    loadingText?: string | JSX.Element;
    loadedErr?: string | JSX.Element;
}

export const Loading = ({ loaded, loadingText, loadedErr, children }: PropsWithChildren<LoadingProps>) => {
    return (
        <>
            {loaded && !loadedErr ? (
                children
            ) : (
                <Box css={styles}>
                    {loaded && loadedErr ? (
                        <Stack alignItems="center" className="error">
                            <ErrorOutlineOutlinedIcon />
                            <Typography variant="body2" color="error">
                                {loadedErr}
                            </Typography>
                        </Stack>
                    ) : (
                        <>
                            <CircularProgress size={15} />
                            {loadingText && (
                                <Typography color="text.secondary" sx={{ marginTop: '8px' }}>
                                    {loadingText}
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
            )}
        </>
    );
};

const styles = (theme: Theme) => css`
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .error {
        svg {
            color: ${theme.palette.error.main};
            margin-bottom: 5px;
        }
    }
    svg {
        color: ${theme.palette.text.secondary};
    }
`;
