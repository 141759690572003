/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { userClient } from 'api/client';
import { ChangePasswordRequest, SendCodeRequest } from 'proto/v1alpha1/user_pb';
import { useAppDispatch } from 'hooks/redux';
import { login } from 'modules/user';
import { LoadingButton } from '@mui/lab';

const emailRegexp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

export const LoginForm = ({ onSuccess, onEnd }: { onSuccess: (user: any) => void; onEnd: () => void }) => {
    const dispatch = useAppDispatch();
    const [loginType, setLoginType] = useState<'password' | 'code' | 'forget' | 'changePwd'>('password');
    const [showPassword, setShowPassword] = useState(false);
    const [formValues, setFormValues] = useState<{ [key: string]: string }>({
        email: '',
        password: '',
        code: '',
    });
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    const [codeCount, setCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const setError = useCallback((k: string, err: string) => setFormErrors((pre) => ({ ...pre, [k]: err })), []);
    const setValue = useCallback((k: string, v: string) => {
        setFormValues((pre) => ({ ...pre, [k]: v }));
        setError(k, '');
        setError('form', '');
    }, []);
    const handleSendCode = () => {
        const email = formValues.email.trim();
        if (!emailRegexp.test(email)) {
            setError('email', '请输入正确的邮箱');
            return;
        }
        userClient.sendCode(new SendCodeRequest().setPhoneOrEmail(email)).catch((e) => {
            setError('code', e.message);
        });
        setValue('code', '');
        startCountdown();
    };
    const startCountdown = useCallback(() => {
        let second = 60;
        let i = setInterval(() => {
            setCount(second--);
            if (second < 0) {
                clearInterval(i);
            }
        }, 1000);
    }, []);

    const handleLogin = () => {
        const email = formValues.email.trim();
        let password = formValues.password.trim();
        let code = formValues.code.trim();

        if (loginType === 'password' || loginType === 'code' || loginType === 'forget') {
            if (email === '') {
                setError('email', '邮箱不能为空');
                return;
            }
            if (!emailRegexp.test(email)) {
                setError('email', '请输入正确的邮箱');
                return;
            }
        }

        if (loginType === 'password' || loginType === 'changePwd') {
            if (password === '') {
                setError('password', '密码不能为空');
                return;
            }
        }

        if (loginType === 'changePwd') {
            if (password.length < 6) {
                setError('password', '密码长度不能小于6位');
                return;
            }
        }

        if (loginType === 'code' || loginType === 'forget') {
            if (code === '') {
                setError('code', '验证码不能为空');
                return;
            }
            password = '';
        }

        if (loginType !== 'changePwd') {
            setLoading(true);
            dispatch(
                login(loginType === 'password' ? { emailOrPhone: email, password: password } : { emailOrPhone: email, code: code })
            ).then((res: any) => {
                setLoading(false);
                if (res.error) {
                    setError('form', res.error.message);
                    return;
                }

                onSuccess(res.payload);

                if (!res.payload.hasPassword || loginType === 'forget') {
                    setValue('password', '');
                    setError('password', '');
                    setError('form', '');
                    setLoginType('changePwd');
                    return;
                }

                onEnd();
            });
        } else {
            setLoading(true);
            userClient
                .changePassword(new ChangePasswordRequest().setPassword(password))
                .then((res) => {
                    setLoading(false);
                    onEnd();
                })
                .catch((e) => {
                    setError('password', e.message);
                });
        }
    };

    return (
        <Box css={styles}>
            {loginType !== 'changePwd' && (
                <TextField
                    sx={{ marginBottom: 1.5 }}
                    fullWidth
                    placeholder="请输入邮箱地址"
                    size="medium"
                    label="邮箱"
                    type="text"
                    error={!!formErrors.email}
                    helperText={formErrors.email ?? ''}
                    value={formValues.email}
                    onChange={(e) => setValue('email', e.target.value)}
                />
            )}
            {(loginType === 'password' || loginType === 'changePwd') && (
                <FormControl sx={{ marginBottom: 1.5 }} variant="outlined" fullWidth error={!!formErrors.password}>
                    <InputLabel>密码</InputLabel>
                    <OutlinedInput
                        label="密码"
                        placeholder="请输入密码"
                        value={formValues.password}
                        onChange={(e) => setValue('password', e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText>{formErrors.password ?? ''}</FormHelperText>
                </FormControl>
            )}
            {(loginType === 'code' || loginType === 'forget') && (
                <FormControl sx={{ marginBottom: 1.5 }} variant="outlined" fullWidth error={!!formErrors.code}>
                    <InputLabel>验证码</InputLabel>
                    <OutlinedInput
                        placeholder="请输入验证码"
                        value={formValues.code}
                        onChange={(e) => setValue('code', e.target.value)}
                        type="text"
                        endAdornment={
                            <InputAdornment position="end">
                                {codeCount > 0 ? (
                                    <Typography color="text.secondary">{codeCount}秒后重试</Typography>
                                ) : (
                                    <Button onClick={handleSendCode}>发送验证码</Button>
                                )}
                            </InputAdornment>
                        }
                        label="验证码"
                    />
                    <FormHelperText>{formErrors.code ?? ''}</FormHelperText>
                </FormControl>
            )}
            {!!formErrors.form && (
                <FormControl error>
                    <FormHelperText>{formErrors.form}</FormHelperText>
                </FormControl>
            )}
            <LoadingButton
                loading={loading}
                fullWidth
                variant="contained"
                size="large"
                sx={{ marginTop: 1, marginBottom: 1 }}
                onClick={handleLogin}
            >
                {loginType === 'password' ? '立即登录' : ''}
                {loginType === 'code' ? '注册/登录' : ''}
                {loginType === 'forget' ? '注册/登录' : ''}
                {loginType === 'changePwd' ? '设置新密码' : ''}
            </LoadingButton>
            <Box className="entry">
                <Typography
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        if (loginType === 'password') setLoginType('forget');
                    }}
                    component="span"
                    color="primary"
                    variant="body2"
                >
                    {loginType === 'password' ? '忘记密码?' : ''}
                </Typography>
                <Typography
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        if (loginType === 'password') setLoginType('code');
                        else if (loginType === 'code') setLoginType('password');
                        else if (loginType === 'forget') setLoginType('password');
                    }}
                    component="span"
                    color="primary"
                    variant="body2"
                >
                    {loginType === 'password' ? '验证码注册/登录' : ''}
                    {loginType === 'code' ? '密码登录' : ''}
                    {loginType === 'forget' ? '密码登录' : ''}
                </Typography>
            </Box>
        </Box>
    );
};

const styles = (theme: Theme) => css`
    max-width: 380px;

    .entry {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
