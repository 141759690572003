/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { LoginForm } from 'components/loginForm';
import { forwardRef, PropsWithChildren, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { RootPageContext } from 'pages/rootPage';

export interface BaseDlgPropx {
    title?: string;
    open?: boolean;
    onClose?: (reason: string) => void;
}

export const BaseDlg = ({ title, open, onClose, children }: PropsWithChildren<BaseDlgPropx>) => {
    const close = useCallback(
        (e: any, reason: string) => {
            if (onClose) onClose(reason);
        },
        [onClose]
    );

    return (
        <Dialog open={!!open} onClose={close}>
            <Box css={styles}>
                <Box>
                    <Typography variant="subtitle1">{title ?? ''}</Typography>
                    <IconButton onClick={(e: any) => close(e, 'clickClose')}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>
                <Box>{children}</Box>
            </Box>
        </Dialog>
    );
};

const styles = (theme: Theme) => css`
    & > :nth-of-type(1) {
        padding: 20px 18px 18px 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
            color: ${theme.palette.text.disabled};
            height: 16px;
            width: 16px;
        }
    }

    & > :nth-of-type(2) {
        padding: 0px 23px 25px 23px;
    }
`;
