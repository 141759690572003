import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

export const HeadManager = ({ children }: PropsWithChildren<{}>) => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="manifest" href="/manifest.json" />

                <title>章鱼AI</title>
            </Helmet>
            {children}
        </>
    );
};
