/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Extension } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { userClient } from 'api/client';
import { BaseDlg, BaseDlgPropx } from 'components/baseDlg';
import { Loading } from 'components/ui/loading';
import { ShareRequest, ShareResponse } from 'proto/v1alpha1/user_pb';
import { useCallback, useEffect, useState } from 'react';
import { WithLoadStatus } from 'utils/common';

export interface ShareCreateDlgProps extends BaseDlgPropx {
    shareChat?: string;
}

export const ShareCreateDlg = ({ shareChat, ...baseDlgProps }: ShareCreateDlgProps) => {
    const [share, setShare] = useState<WithLoadStatus<ShareResponse.AsObject>>({ loading: true });
    useEffect(() => {
        setShare({ loading: true });
        userClient
            .share(new ShareRequest().setChat(shareChat ? new ShareRequest.ShareChat().setConvId(shareChat) : undefined))
            .then((res) => {
                setShare({ loading: false, data: res.toObject() });
            })
            .catch((e) => {
                setShare({ loading: false, loadErr: e.message });
            });
    }, [shareChat]);

    const copy = useCallback(() => {
        if (share.data) {
            navigator.clipboard.writeText(share.data.shareMessage);
        }
    }, [share]);

    return (
        <BaseDlg {...baseDlgProps}>
            <Box css={styles}>
                <Loading loaded={share.loading === false} loadedErr={share.loadErr} loadingText="正在生成分享信息...">
                    <Typography>{share.data?.url}</Typography>
                    <Button onClick={copy} variant="contained">
                        复制链接
                    </Button>
                </Loading>
            </Box>
        </BaseDlg>
    );
};

const styles = (theme: Theme) => css`
    min-width: 360px;
    min-height: 230px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > p {
        padding: 10px 15px;
        border: 1px solid ${theme.palette.divider};
        border-radius: 5px;
    }

    & > button {
        margin-top: 20px;
        min-width: 180px;
    }
`;
