/**  @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { Prism as SyntaxHighlighterLight } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { oneLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { Mermaid } from './mermaid';

// import tsx from 'react-syntax-highlighter/dist/cjs/languages/prism/tsx';
// import typescript from 'react-syntax-highlighter/dist/cjs/languages/prism/typescript';
// import scss from 'react-syntax-highlighter/dist/cjs/languages/prism/scss';
// import bash from 'react-syntax-highlighter/dist/cjs/languages/prism/bash';
// import markdown from 'react-syntax-highlighter/dist/cjs/languages/prism/markdown';
// import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json';
// import python from 'react-syntax-highlighter/dist/cjs/languages/prism/python';
// import java from 'react-syntax-highlighter/dist/cjs/languages/prism/java';

// SyntaxHighlighterLight.registerLanguage('tsx', tsx);
// SyntaxHighlighterLight.registerLanguage('typescript', typescript);
// SyntaxHighlighterLight.registerLanguage('scss', scss);
// SyntaxHighlighterLight.registerLanguage('bash', bash);
// SyntaxHighlighterLight.registerLanguage('markdown', markdown);
// SyntaxHighlighterLight.registerLanguage('json', json);
// SyntaxHighlighterLight.registerLanguage('python', python);
// SyntaxHighlighterLight.registerLanguage('java', java);

export const CodeRenderer = (props: any) => {
    const { node, inline, className } = props;
    const syntaxTheme = oneLight;
    const match = /language-(\w+)/.exec(className || '');

    // const hasMeta = node?.data?.meta;
    // const applyHighlights = (applyHighlights: number) => {
    //     if (hasMeta) {
    //         const RE = /{([\d,-]+)}/;
    //         const metadata = node.data.meta?.replace(/\s/g, '');
    //         const exec = RE.exec(metadata);
    //         const strlineNumbers = exec ? exec[1] : '0';
    //         const highlightLines = rangeParser(strlineNumbers);
    //         const highlight = highlightLines;
    //         const data = highlight.includes(applyHighlights) ? 'highlight' : null;
    //         return { data };
    //     } else {
    //         return {};
    //     }
    // };

    return match ? (
        match[1] === 'mermaid' ? (
            <Mermaid text={props.children[0]} />
        ) : (
            <SyntaxHighlighterLight
                style={syntaxTheme}
                language={match[1]}
                PreTag="div"
                className={c.name}
                showLineNumbers={true}
                // wrapLines={hasMeta ? true : false}
                // useInlineStyles={true}
                // lineProps={applyHighlights}
                // {...props}
            >
                {props.children}
            </SyntaxHighlighterLight>
        )
    ) : (
        <code className={className}>{props.children}</code>
    );
};

const c = css``;
