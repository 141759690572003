import { useEffect, useRef, useState } from 'react';
import mermaid from 'mermaid';
import { Box } from '@mui/material';

export const Mermaid = ({ text }: { text: string }) => {
    const container = useRef<HTMLDivElement>();

    useEffect(() => {
        const render = async () => {
            if (!text || !container.current) return;
            try {
                const canParse = await mermaid.parse(text);
                if (!canParse) {
                    throw new Error('mermaind syntax error');
                }

                const id = 'mermaid' + Math.random().toString(36).slice(2);
                const { svg, bindFunctions } = await mermaid.render(id, text);
                container.current.innerHTML = svg;
                bindFunctions?.(container.current);
            } catch (e) {
                console.log('e:', e);
                container.current.innerHTML = text;
            }
        };
        render();
    }, [text]);

    return <Box ref={container} sx={{ svg: { width: '100%', height: '100%' } }} />;
};
