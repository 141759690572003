import { Theme } from '@emotion/react';
import { darkTheme } from 'components/theme/dark';
import { lightTheme } from 'components/theme/light';

export const getTheme = (name: string): Theme => {
    switch (name) {
        case 'light':
            return lightTheme;
        case 'dark':
            return darkTheme;
        default:
            return {} as Theme;
    }
};
