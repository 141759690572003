import { Theme } from '@emotion/react';
import { createTheme } from '@mui/material';
import { CustomTheme } from 'components/theme/theme';

export const baseTheme: Pick<CustomTheme, 'sizes' | 'zIndexs'> = {
    sizes: {
        navbarWidth: 256,
        topbarHeight: 57,
        chatContentMinWidth: 420,
        chatContentMaxWidth: 880,
    },
    zIndexs: {
        navbar: 100,
    },
};

export const createMuiTheme = (theme: CustomTheme) =>
    createTheme({
        palette: {
            primary: theme.colors.primary,
            background: {
                default: theme.colors.background.main,
                paper: theme.colors.background.dark,
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: [
                '"Google Sans"',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),

            body1: {
                fontSize: '1rem',
            },

            body2: {
                fontSize: '0.875rem',
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        padding: '5px 10px',
                    },
                    startIcon: {
                        marginRight: '4px',
                    },
                    endIcon: {
                        marginLeft: '4px',
                    },
                    sizeSmall: {
                        minWidth: 30,
                        padding: '1px 6px',
                    },
                    sizeLarge: {
                        height: 54,
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        fontSize: '0.9375rem',
                    },
                    sizeSmall: {
                        fontSize: '0.875rem',
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontSize: '0.875rem',
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        fontSize: '0.875rem',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: '13px',
                    },
                },
            },
        },
    });
