/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box } from '@mui/material';
import { LoginDlg, LoginDlgRef } from 'components/loginDlg';
import { useAppDispatch } from 'hooks/redux';
import { getModels } from 'modules/chat';
import { useEffect, useMemo, useRef } from 'react';
import { Outlet } from 'react-router-dom';

export interface RootPageContext {
    doLogin: (success: () => void) => void;
}

export const RootPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getModels());
    });

    const loginRef = useRef<LoginDlgRef>();
    const rootPageContext = useMemo(
        () => ({
            doLogin: (success: () => void) => {
                if (loginRef.current) loginRef.current.doLogin(success);
            },
        }),
        []
    );

    return (
        <Box css={styles}>
            <Outlet context={rootPageContext} />
            <LoginDlg ref={loginRef} />
        </Box>
    );
};

const styles = (theme: Theme) => css`
    width: 100%;
    height: 100%;
`;
