/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { ChatNav } from 'components/chatNav';
import { Layout } from 'components/layout';
import { LoginCheck } from 'components/loginCheck';
import { Outlet } from 'react-router-dom';

export const ChatPage = () => {
    return (
        <LoginCheck force>
            <Layout NavMenu={ChatNav}>
                <Outlet />
            </Layout>
        </LoginCheck>
    );
};

const styles = (theme: Theme) => css``;
