/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box } from '@mui/material';
import { ChatContent } from 'components/chatContent';
import { ChatConversation } from 'proto/v1alpha1/chat_pb';

export const ShareChat = ({ conv }: { conv: ChatConversation.AsObject }) => {
    return (
        <Box css={styles}>
            <ChatContent conv={conv} />
        </Box>
    );
};

const styles = (theme: Theme) => css``;
