/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import { ModelList } from 'components/modeList';
import { useAppSelector } from 'hooks/redux';
import { selectUser } from 'modules/user';
import { RootPageContext } from 'pages/rootPage';
import { Model } from 'proto/v1alpha1/chat_pb';
import { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

const showModelList = ['GPT4', 'Genimi-pro', 'ERNIE4', 'QWEN-plus', 'ChatGLM', 'XinHuo', 'Yi-34B'];
export const Home = () => {
    const navigate = useNavigate();
    const { data: user } = useAppSelector(selectUser);
    const [chooseModels, setChooseModels] = useState<string[]>([]);
    const onModelChoose = (models: string[]) => {
        setChooseModels(models);
    };

    const rootPageContext: RootPageContext = useOutletContext();

    const handleChatClick = () => {
        let to = '/chat';
        if (chooseModels.length > 0) {
            to += '?models=' + chooseModels.join(',');
        }

        if (!user) {
            rootPageContext.doLogin(() => {
                navigate(to);
            });
            return;
        } else {
            navigate(to);
        }
    };

    const handleLogin = () => {
        if (!user) {
            rootPageContext.doLogin(() => {
                navigate('/chat');
            });
            return;
        } else {
            navigate('/chat');
        }
    };
    return (
        <Box css={styles}>
            <Box className="logo">
                <img src="/logo192.png" />
                <Typography variant="h6">章鱼AI</Typography>
            </Box>
            {/* <Typography className="title" variant="h5">
                大模型一网打尽
            </Typography> */}
            <ModelList
                models={chooseModels}
                filter={(m: Model.AsObject) => showModelList.findIndex((item) => item === m.id) > -1}
                showMore
                onModelChoose={onModelChoose}
            />
            <Button className="startButton" variant="contained" size="large" onClick={handleChatClick}>
                选择模型，开始对话
            </Button>
            <Button size="medium" onClick={handleLogin}>
                {user ? '查看历史会话' : '立即登录'}
            </Button>
        </Box>
    );
};

const styles = (theme: Theme) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 56px;

    .logo {
        img {
            width: 36px;
        }
        color: ${theme.c.colors.primary.main};
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 56px;
        h6 {
            font-size: 0.985rem;
            margin-top: 8px;
        }
    }

    .title {
        margin: 15px 0px;
    }

    & > div:nth-of-type(2) {
        max-width: 900px;
    }

    .startButton {
        margin: 32px 0px 16px 0px;
        width: 330px;
        font-size: 1.125rem;
    }
`;
