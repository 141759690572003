/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box } from '@mui/material';
import { ChatContainer } from 'components/chatContainer';
import { ChatContent } from 'components/chatContent';
import { ChatTitle } from 'components/chatTitle';
import { Loading } from 'components/ui/loading';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getConversion, selectCurrConv } from 'modules/chat';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const ChatDetailPage = () => {
    const dispatch = useAppDispatch();
    const { id = '' } = useParams();
    const { loading, loadErr, data: conv } = useAppSelector(selectCurrConv);

    useEffect(() => {
        if (id !== '') {
            dispatch(getConversion({ id }));
        }
    }, [id]);

    return (
        <Loading loaded={loading === false} loadedErr={loadErr}>
            <ChatTitle />
            <ChatContainer>{conv && <ChatContent conv={conv} initScrollToBottom />}</ChatContainer>
        </Loading>
    );
};

const styles = (theme: Theme) => css`
    width: 100%;
    height: 100%;
`;
