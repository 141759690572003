/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, useMediaQuery } from '@mui/material';
import { ChatContainer } from 'components/chatContainer';
import { ChatContent } from 'components/chatContent';
import { ChatTitle } from 'components/chatTitle';
import { ChooseModel } from 'components/chooseModel';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { initConv, selectCurrConv } from 'modules/chat';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const ChatNewPage = () => {
    const dispatch = useAppDispatch();
    const { data: conv } = useAppSelector(selectCurrConv);
    const [searchParams, setSearchParams] = useSearchParams();
    const initModels = searchParams.get('models');

    useEffect(() => {
        dispatch(initConv({ models: initModels ? initModels.split(',') : [] }));
    }, []);

    return (
        <>
            <ChatTitle />
            <ChatContainer>{!conv || conv?.id === '' ? <ChooseModel /> : <ChatContent conv={conv} />}</ChatContainer>
        </>
    );
};

const styles = (theme: Theme) => css`
    height: 100%;
    width: 100%;
`;
