/**
 * @fileoverview gRPC-Web generated client stub for api.v1alpha1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: proto/v1alpha1/user.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');



var proto_v1alpha1_chat_pb = require('../../proto/v1alpha1/chat_pb.js')

const proto = {};
proto.api = {};
proto.api.v1alpha1 = require('./user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.v1alpha1.UserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.v1alpha1.UserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.v1alpha1.SendCodeRequest,
 *   !proto.api.v1alpha1.SendCodeResponse>}
 */
const methodDescriptor_UserService_SendCode = new grpc.web.MethodDescriptor(
  '/api.v1alpha1.UserService/SendCode',
  grpc.web.MethodType.UNARY,
  proto.api.v1alpha1.SendCodeRequest,
  proto.api.v1alpha1.SendCodeResponse,
  /**
   * @param {!proto.api.v1alpha1.SendCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.v1alpha1.SendCodeResponse.deserializeBinary
);


/**
 * @param {!proto.api.v1alpha1.SendCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.v1alpha1.SendCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.SendCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.UserServiceClient.prototype.sendCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.v1alpha1.UserService/SendCode',
      request,
      metadata || {},
      methodDescriptor_UserService_SendCode,
      callback);
};


/**
 * @param {!proto.api.v1alpha1.SendCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.v1alpha1.SendCodeResponse>}
 *     Promise that resolves to the response
 */
proto.api.v1alpha1.UserServicePromiseClient.prototype.sendCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.v1alpha1.UserService/SendCode',
      request,
      metadata || {},
      methodDescriptor_UserService_SendCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.v1alpha1.LoginRequest,
 *   !proto.api.v1alpha1.LoginResponse>}
 */
const methodDescriptor_UserService_Login = new grpc.web.MethodDescriptor(
  '/api.v1alpha1.UserService/Login',
  grpc.web.MethodType.UNARY,
  proto.api.v1alpha1.LoginRequest,
  proto.api.v1alpha1.LoginResponse,
  /**
   * @param {!proto.api.v1alpha1.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.v1alpha1.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.api.v1alpha1.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.v1alpha1.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.UserServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.v1alpha1.UserService/Login',
      request,
      metadata || {},
      methodDescriptor_UserService_Login,
      callback);
};


/**
 * @param {!proto.api.v1alpha1.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.v1alpha1.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.api.v1alpha1.UserServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.v1alpha1.UserService/Login',
      request,
      metadata || {},
      methodDescriptor_UserService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.v1alpha1.LogoutRequest,
 *   !proto.api.v1alpha1.LogoutResponse>}
 */
const methodDescriptor_UserService_Logout = new grpc.web.MethodDescriptor(
  '/api.v1alpha1.UserService/Logout',
  grpc.web.MethodType.UNARY,
  proto.api.v1alpha1.LogoutRequest,
  proto.api.v1alpha1.LogoutResponse,
  /**
   * @param {!proto.api.v1alpha1.LogoutRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.v1alpha1.LogoutResponse.deserializeBinary
);


/**
 * @param {!proto.api.v1alpha1.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.v1alpha1.LogoutResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.LogoutResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.UserServiceClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.v1alpha1.UserService/Logout',
      request,
      metadata || {},
      methodDescriptor_UserService_Logout,
      callback);
};


/**
 * @param {!proto.api.v1alpha1.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.v1alpha1.LogoutResponse>}
 *     Promise that resolves to the response
 */
proto.api.v1alpha1.UserServicePromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.v1alpha1.UserService/Logout',
      request,
      metadata || {},
      methodDescriptor_UserService_Logout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.v1alpha1.ChangePasswordRequest,
 *   !proto.api.v1alpha1.ChangePasswordResponse>}
 */
const methodDescriptor_UserService_ChangePassword = new grpc.web.MethodDescriptor(
  '/api.v1alpha1.UserService/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto.api.v1alpha1.ChangePasswordRequest,
  proto.api.v1alpha1.ChangePasswordResponse,
  /**
   * @param {!proto.api.v1alpha1.ChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.v1alpha1.ChangePasswordResponse.deserializeBinary
);


/**
 * @param {!proto.api.v1alpha1.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.v1alpha1.ChangePasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.ChangePasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.UserServiceClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.v1alpha1.UserService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_UserService_ChangePassword,
      callback);
};


/**
 * @param {!proto.api.v1alpha1.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.v1alpha1.ChangePasswordResponse>}
 *     Promise that resolves to the response
 */
proto.api.v1alpha1.UserServicePromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.v1alpha1.UserService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_UserService_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.v1alpha1.ShareRequest,
 *   !proto.api.v1alpha1.ShareResponse>}
 */
const methodDescriptor_UserService_Share = new grpc.web.MethodDescriptor(
  '/api.v1alpha1.UserService/Share',
  grpc.web.MethodType.UNARY,
  proto.api.v1alpha1.ShareRequest,
  proto.api.v1alpha1.ShareResponse,
  /**
   * @param {!proto.api.v1alpha1.ShareRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.v1alpha1.ShareResponse.deserializeBinary
);


/**
 * @param {!proto.api.v1alpha1.ShareRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.v1alpha1.ShareResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.ShareResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.UserServiceClient.prototype.share =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.v1alpha1.UserService/Share',
      request,
      metadata || {},
      methodDescriptor_UserService_Share,
      callback);
};


/**
 * @param {!proto.api.v1alpha1.ShareRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.v1alpha1.ShareResponse>}
 *     Promise that resolves to the response
 */
proto.api.v1alpha1.UserServicePromiseClient.prototype.share =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.v1alpha1.UserService/Share',
      request,
      metadata || {},
      methodDescriptor_UserService_Share);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.v1alpha1.GetShareRequest,
 *   !proto.api.v1alpha1.GetShareResponse>}
 */
const methodDescriptor_UserService_GetShare = new grpc.web.MethodDescriptor(
  '/api.v1alpha1.UserService/GetShare',
  grpc.web.MethodType.UNARY,
  proto.api.v1alpha1.GetShareRequest,
  proto.api.v1alpha1.GetShareResponse,
  /**
   * @param {!proto.api.v1alpha1.GetShareRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.v1alpha1.GetShareResponse.deserializeBinary
);


/**
 * @param {!proto.api.v1alpha1.GetShareRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.v1alpha1.GetShareResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.GetShareResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.UserServiceClient.prototype.getShare =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.v1alpha1.UserService/GetShare',
      request,
      metadata || {},
      methodDescriptor_UserService_GetShare,
      callback);
};


/**
 * @param {!proto.api.v1alpha1.GetShareRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.v1alpha1.GetShareResponse>}
 *     Promise that resolves to the response
 */
proto.api.v1alpha1.UserServicePromiseClient.prototype.getShare =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.v1alpha1.UserService/GetShare',
      request,
      metadata || {},
      methodDescriptor_UserService_GetShare);
};


module.exports = proto.api.v1alpha1;

