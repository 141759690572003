import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { chatApi } from 'api/chat';
import { chatClient } from 'api/client';
import {
    ChatConversation,
    ChatResponse,
    DeleteConversationRequest,
    GetConversationRequest,
    GetConversationsRequest,
    GetModelsRequest,
    Model,
    UpdateConversationTitleRequest,
} from 'proto/v1alpha1/chat_pb';
import { LoadStatus, WithLoadStatus } from 'utils/common';

const MODULE_NAME = 'chat';

export const getModels = createAsyncThunk<Model.AsObject[], void>(`${MODULE_NAME}/getModels`, async () => {
    return (await chatClient.getModels(new GetModelsRequest())).toObject().modelsList;
});

export const getConversationList = createAsyncThunk<ChatConversation.AsObject[], void>(
    `${MODULE_NAME}/getConversationList`,
    async (_, thunkAPI) => {
        const convs = (await chatClient.getConversations(new GetConversationsRequest())).toObject().listList;
        // for (let conv of convs) {
        //     if (conv.title === '') {
        //         const id = conv.id;
        //         thunkAPI.dispatch(updateConversationTitle({ id }));
        //     }
        // }
        return convs;
    }
);

export const getConversion = createAsyncThunk<ChatConversation.AsObject, { id: string }>(
    `${MODULE_NAME}/getCurrConversion`,
    async (args, thunkAPI) => {
        const conv = (await chatClient.getConversation(new GetConversationRequest().setId(args.id))).toObject()
            .conversation as ChatConversation.AsObject;
        if (conv.title === '') {
            thunkAPI.dispatch(updateConversationTitle({ id: conv.id }));
        }
        return conv;
    }
);

export const deleteConversion = createAsyncThunk<string, { id: string }>(`${MODULE_NAME}/deleteConversion`, async (args) => {
    return (await chatClient.deleteConversation(new DeleteConversationRequest().setId(args.id))).toObject().id;
});

export const updateConversationTitle = createAsyncThunk<string, { id: string; title?: string }>(
    `${MODULE_NAME}/updateConversationTitle`,
    async (args) => {
        return (
            await chatClient.updateConversationTitle(new UpdateConversationTitleRequest().setId(args.id).setTitle(args.title ?? ''))
        ).toObject().title;
    }
);
export interface ChatModels {
    models: string[];
    objs: { [name: string]: Model.AsObject };
}

export interface ChatState {
    models: WithLoadStatus<ChatModels>;
    convList: WithLoadStatus<ChatConversation.AsObject[]>;
    currConv: WithLoadStatus<ChatConversation.AsObject>;
}

const initialState: ChatState = {
    models: {},
    convList: {},
    currConv: {},
};

export const chatSlice = createSlice({
    name: MODULE_NAME,
    initialState: initialState,
    reducers: {
        initConv: (state: ChatState, action: PayloadAction<{ models?: string[] }>) => {
            state.currConv = {
                loading: false,
                data: {
                    id: '',
                    title: '',
                    modelsList: action.payload.models || [],
                    messagesList: [],
                },
            };
        },
        setConvModels: (state: ChatState, action: PayloadAction<string[]>) => {
            if (state.currConv.data) {
                state.currConv.data.modelsList = action.payload;
                state.currConv = {
                    ...state.currConv,
                };
            }
        },
        addConvMessage: (state: ChatState, action: PayloadAction<ChatResponse.AsObject>) => {
            const msg = action.payload.message;
            if (!msg) return;

            if (!state.convList.data || state.convList.data.findIndex((item) => item.id === msg.conversationId) === -1) {
                state.convList = {
                    ...state.convList,
                    data: [
                        {
                            id: msg.conversationId,
                            title: '',
                            modelsList: [],
                            messagesList: [msg],
                        },
                        ...(state.convList.data || []),
                    ],
                };
            }

            if (!state.currConv.data) {
                state.currConv.data = {
                    id: '',
                    title: '',
                    modelsList: [],
                    messagesList: [],
                };
            }

            if (state.currConv.data.id === '') state.currConv.data.id = msg.conversationId;

            let modified = false;
            for (let i = state.currConv.data.messagesList.length - 1; i >= 0; i--) {
                if (state.currConv.data.messagesList[i].id === msg.id) {
                    state.currConv.data.messagesList[i] = msg;
                    modified = true;
                    break;
                }
            }
            if (!modified) state.currConv.data.messagesList.push(msg);

            state.currConv = {
                ...state.currConv,
            };
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getModels.pending, (state, action) => {
                state.models = { loading: true };
            })
            .addCase(getModels.fulfilled, (state, action) => {
                const data: ChatModels = {
                    models: [],
                    objs: {},
                };
                for (let m of action.payload) {
                    data.models.push(m.id);
                    data.objs[m.id] = m;
                }
                state.models = { loading: false, data: data };
            })
            .addCase(getModels.rejected, (state, action) => {
                state.models = { loading: false, loadErr: action.error.message };
            })
            .addCase(getConversationList.pending, (state, action) => {
                state.convList = { loading: true };
            })
            .addCase(getConversationList.fulfilled, (state, action) => {
                state.convList = { loading: false, data: action.payload };
            })
            .addCase(getConversationList.rejected, (state, action) => {
                state.convList = { loading: false, loadErr: action.error.message };
            })
            .addCase(getConversion.pending, (state, action) => {
                state.currConv = { loading: true };
            })
            .addCase(getConversion.fulfilled, (state, action) => {
                state.currConv = { loading: false, data: action.payload };
            })
            .addCase(getConversion.rejected, (state, action) => {
                state.currConv = { loading: false, loadErr: action.error.message };
            })
            .addCase(deleteConversion.fulfilled, (state, action) => {
                if (state.convList.data) {
                    const index = state.convList.data.findIndex((item) => item.id === action.payload);
                    if (index > -1) {
                        state.convList = {
                            ...state.convList,
                            data: state.convList.data.filter((item) => item.id !== action.payload),
                        };
                    }
                }
            })
            .addCase(updateConversationTitle.fulfilled, (state, action) => {
                if (state.convList.data) {
                    for (let conv of state.convList.data) {
                        if (conv.id === action.meta.arg.id) {
                            conv.title = action.payload;
                            state.convList = {
                                ...state.convList,
                                data: state.convList.data,
                            };
                            break;
                        }
                    }
                }

                if (state.currConv.data && state.currConv.data.id == action.meta.arg.id) {
                    const conv = {
                        ...state.currConv.data,
                        title: action.payload,
                    };
                    state.currConv = {
                        ...state.currConv,
                        data: conv,
                    };
                }
            });
    },
});
