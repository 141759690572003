/**
 * @fileoverview gRPC-Web generated client stub for api.v1alpha1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: proto/v1alpha1/chat.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');



const proto = {};
proto.api = {};
proto.api.v1alpha1 = require('./chat_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.v1alpha1.ChatServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.v1alpha1.ChatServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.v1alpha1.GetModelsRequest,
 *   !proto.api.v1alpha1.GetModelsResponse>}
 */
const methodDescriptor_ChatService_GetModels = new grpc.web.MethodDescriptor(
  '/api.v1alpha1.ChatService/GetModels',
  grpc.web.MethodType.UNARY,
  proto.api.v1alpha1.GetModelsRequest,
  proto.api.v1alpha1.GetModelsResponse,
  /**
   * @param {!proto.api.v1alpha1.GetModelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.v1alpha1.GetModelsResponse.deserializeBinary
);


/**
 * @param {!proto.api.v1alpha1.GetModelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.v1alpha1.GetModelsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.GetModelsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.ChatServiceClient.prototype.getModels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.v1alpha1.ChatService/GetModels',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetModels,
      callback);
};


/**
 * @param {!proto.api.v1alpha1.GetModelsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.v1alpha1.GetModelsResponse>}
 *     Promise that resolves to the response
 */
proto.api.v1alpha1.ChatServicePromiseClient.prototype.getModels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.v1alpha1.ChatService/GetModels',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetModels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.v1alpha1.ChatRequest,
 *   !proto.api.v1alpha1.ChatResponse>}
 */
const methodDescriptor_ChatService_Chat = new grpc.web.MethodDescriptor(
  '/api.v1alpha1.ChatService/Chat',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.api.v1alpha1.ChatRequest,
  proto.api.v1alpha1.ChatResponse,
  /**
   * @param {!proto.api.v1alpha1.ChatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.v1alpha1.ChatResponse.deserializeBinary
);


/**
 * @param {!proto.api.v1alpha1.ChatRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.ChatResponse>}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.ChatServiceClient.prototype.chat =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.v1alpha1.ChatService/Chat',
      request,
      metadata || {},
      methodDescriptor_ChatService_Chat);
};


/**
 * @param {!proto.api.v1alpha1.ChatRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.ChatResponse>}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.ChatServicePromiseClient.prototype.chat =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.v1alpha1.ChatService/Chat',
      request,
      metadata || {},
      methodDescriptor_ChatService_Chat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.v1alpha1.GetConversationsRequest,
 *   !proto.api.v1alpha1.GetConversationsResponse>}
 */
const methodDescriptor_ChatService_GetConversations = new grpc.web.MethodDescriptor(
  '/api.v1alpha1.ChatService/GetConversations',
  grpc.web.MethodType.UNARY,
  proto.api.v1alpha1.GetConversationsRequest,
  proto.api.v1alpha1.GetConversationsResponse,
  /**
   * @param {!proto.api.v1alpha1.GetConversationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.v1alpha1.GetConversationsResponse.deserializeBinary
);


/**
 * @param {!proto.api.v1alpha1.GetConversationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.v1alpha1.GetConversationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.GetConversationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.ChatServiceClient.prototype.getConversations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.v1alpha1.ChatService/GetConversations',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetConversations,
      callback);
};


/**
 * @param {!proto.api.v1alpha1.GetConversationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.v1alpha1.GetConversationsResponse>}
 *     Promise that resolves to the response
 */
proto.api.v1alpha1.ChatServicePromiseClient.prototype.getConversations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.v1alpha1.ChatService/GetConversations',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetConversations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.v1alpha1.GetConversationRequest,
 *   !proto.api.v1alpha1.GetConversationResponse>}
 */
const methodDescriptor_ChatService_GetConversation = new grpc.web.MethodDescriptor(
  '/api.v1alpha1.ChatService/GetConversation',
  grpc.web.MethodType.UNARY,
  proto.api.v1alpha1.GetConversationRequest,
  proto.api.v1alpha1.GetConversationResponse,
  /**
   * @param {!proto.api.v1alpha1.GetConversationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.v1alpha1.GetConversationResponse.deserializeBinary
);


/**
 * @param {!proto.api.v1alpha1.GetConversationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.v1alpha1.GetConversationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.GetConversationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.ChatServiceClient.prototype.getConversation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.v1alpha1.ChatService/GetConversation',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetConversation,
      callback);
};


/**
 * @param {!proto.api.v1alpha1.GetConversationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.v1alpha1.GetConversationResponse>}
 *     Promise that resolves to the response
 */
proto.api.v1alpha1.ChatServicePromiseClient.prototype.getConversation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.v1alpha1.ChatService/GetConversation',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetConversation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.v1alpha1.DeleteConversationRequest,
 *   !proto.api.v1alpha1.DeleteConversationResponse>}
 */
const methodDescriptor_ChatService_DeleteConversation = new grpc.web.MethodDescriptor(
  '/api.v1alpha1.ChatService/DeleteConversation',
  grpc.web.MethodType.UNARY,
  proto.api.v1alpha1.DeleteConversationRequest,
  proto.api.v1alpha1.DeleteConversationResponse,
  /**
   * @param {!proto.api.v1alpha1.DeleteConversationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.v1alpha1.DeleteConversationResponse.deserializeBinary
);


/**
 * @param {!proto.api.v1alpha1.DeleteConversationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.v1alpha1.DeleteConversationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.DeleteConversationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.ChatServiceClient.prototype.deleteConversation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.v1alpha1.ChatService/DeleteConversation',
      request,
      metadata || {},
      methodDescriptor_ChatService_DeleteConversation,
      callback);
};


/**
 * @param {!proto.api.v1alpha1.DeleteConversationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.v1alpha1.DeleteConversationResponse>}
 *     Promise that resolves to the response
 */
proto.api.v1alpha1.ChatServicePromiseClient.prototype.deleteConversation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.v1alpha1.ChatService/DeleteConversation',
      request,
      metadata || {},
      methodDescriptor_ChatService_DeleteConversation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.v1alpha1.UpdateConversationTitleRequest,
 *   !proto.api.v1alpha1.UpdateConversationTitleResponse>}
 */
const methodDescriptor_ChatService_UpdateConversationTitle = new grpc.web.MethodDescriptor(
  '/api.v1alpha1.ChatService/UpdateConversationTitle',
  grpc.web.MethodType.UNARY,
  proto.api.v1alpha1.UpdateConversationTitleRequest,
  proto.api.v1alpha1.UpdateConversationTitleResponse,
  /**
   * @param {!proto.api.v1alpha1.UpdateConversationTitleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.v1alpha1.UpdateConversationTitleResponse.deserializeBinary
);


/**
 * @param {!proto.api.v1alpha1.UpdateConversationTitleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.v1alpha1.UpdateConversationTitleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.v1alpha1.UpdateConversationTitleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.v1alpha1.ChatServiceClient.prototype.updateConversationTitle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.v1alpha1.ChatService/UpdateConversationTitle',
      request,
      metadata || {},
      methodDescriptor_ChatService_UpdateConversationTitle,
      callback);
};


/**
 * @param {!proto.api.v1alpha1.UpdateConversationTitleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.v1alpha1.UpdateConversationTitleResponse>}
 *     Promise that resolves to the response
 */
proto.api.v1alpha1.ChatServicePromiseClient.prototype.updateConversationTitle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.v1alpha1.ChatService/UpdateConversationTitle',
      request,
      metadata || {},
      methodDescriptor_ChatService_UpdateConversationTitle);
};


module.exports = proto.api.v1alpha1;

