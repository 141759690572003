import { Theme, ThemeProvider } from '@emotion/react';
import { HeadManager } from 'components/headManager';
import { LoginCheck } from 'components/loginCheck';
import { getTheme } from 'components/theme';
import { ChatDetailPage } from 'pages/chatDetailPage';
import { ChatNewPage } from 'pages/chatNewPage';
import { ChatPage } from 'pages/chatPage';
import { HomePage } from 'pages/homePage';
import { RootPage } from 'pages/rootPage';
import { SharePage } from 'pages/sharePage';
import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import store from 'store';

export const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<RootPage />}>
                    <Route index element={<HomePage />} />
                    <Route path="/chat" element={<ChatPage />}>
                        <Route index element={<ChatNewPage />} />
                        <Route path="/chat/:id" element={<ChatDetailPage />} />
                    </Route>
                    <Route path="/share/:id" element={<SharePage />} />
                </Route>
            </Routes>
        </Router>
    );
};

export const ThemeContext = createContext<{
    setTheme: Dispatch<SetStateAction<string>>;
}>({
    setTheme: () => {},
});

export const App = () => {
    const [theme, setTheme] = useState<string>('light');

    return (
        <Provider store={store}>
            <ThemeProvider theme={getTheme(theme)}>
                <ThemeContext.Provider value={{ setTheme }}>
                    <HeadManager>
                        <AppRouter />
                    </HeadManager>
                </ThemeContext.Provider>
            </ThemeProvider>
        </Provider>
    );
};
