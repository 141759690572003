/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getConversationList, selectConvList, selectCurrConv } from 'modules/chat';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { Link, useNavigate } from 'react-router-dom';

export const ChatNav = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { data: list = [] } = useAppSelector(selectConvList);
    const { data: curr } = useAppSelector(selectCurrConv);

    useEffect(() => {
        dispatch(getConversationList());
    }, []);

    return (
        <Box css={styles}>
            <Link to="/chat">
                <Button startIcon={<AddIcon />} size="medium" variant="contained">
                    新建对话
                </Button>
            </Link>
            <Box className="chatList">
                {list.map((conv) => (
                    <Link key={conv.id} to={`/chat/${conv.id}`}>
                        <Box className={`chatItem${conv.id === curr?.id ? 'Active' : ''}`}>
                            <ChatBubbleOutlineOutlinedIcon />
                            <Typography variant="body2">{conv.title === '' ? '未命名对话' : conv.title}</Typography>
                        </Box>
                    </Link>
                ))}
            </Box>
        </Box>
    );
};

const styles = (theme: Theme) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & button:nth-of-type(1) {
        margin-left: 23px;
    }

    .chatList {
        flex: 1;
        margin: 16px 0px;
        overflow-y: auto;
        ::-webkit-scrollbar {
            display: none;
        }
    }

    .chatItem,
    .chatItemActive {
        padding: 0 23px;
        height: 36px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: ${theme.c.colors.text.main};
        svg {
            height: 18px;
            width: 18px;
            margin-right: 8px;
        }

        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
            overflow: hidden;
        }
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;

        :hover {
            background: ${theme.c.colors.background.light};
        }
    }

    .chatItemActive {
        color: ${theme.c.colors.text.dark};
        background: ${theme.c.colors.primary.light};
        p {
            font-weight: 500;
        }
    }
`;
