/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/redux';
import { selectModels } from 'modules/chat';
import { Model } from 'proto/v1alpha1/chat_pb';
import { useEffect, useState } from 'react';

export const ModelList = ({
    models,
    filter,
    showMore,
    onModelChoose,
}: {
    models: string[];
    filter?: (m: Model.AsObject) => boolean;
    showMore?: boolean;
    onModelChoose?: (models: string[]) => void;
}) => {
    const { data: allModels } = useAppSelector(selectModels);
    const [choosed, setChoosed] = useState<string[]>(models || []);
    useEffect(() => {
        if (models) setChoosed(models);
    }, [models]);

    const handleModelClick = (model: Model.AsObject) => {
        let next = models;
        if (models.findIndex((name) => name === model.id) > -1) {
            next = models.filter((name) => name !== model.id);
        } else {
            next = [...next, model.id];
        }
        if (onModelChoose) onModelChoose(next);
    };

    return (
        <Box css={styles}>
            <>
                {((filter ? allModels?.models.filter((id) => filter(allModels?.objs[id])) : allModels?.models) || [])
                    .map((id) => allModels?.objs[id])
                    .map((m: any) => (
                        <Box
                            key={m.id}
                            className={`model${choosed.findIndex((item) => item === m.id) > -1 ? 'Active' : ''}`}
                            onClick={() => handleModelClick(m)}
                        >
                            <Typography variant="caption">{m.publisher}</Typography>
                            <Typography variant="subtitle1">{m.name}</Typography>
                            <Typography variant="body2" color="text.secondary">
                                {m.desc}
                            </Typography>
                        </Box>
                    ))}
                {showMore && (
                    <Box className="model">
                        <Typography variant="subtitle1">更多大模型</Typography>
                        <Typography variant="body2" color="text.secondary">
                            尽在章鱼AI...
                        </Typography>
                    </Box>
                )}
            </>
        </Box>
    );
};

const styles = (theme: Theme) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .model,
    .modelActive {
        padding: 16px;
        width: 180px;
        margin: 8px;
        border: 1px solid ${theme.palette.divider};
        border-radius: 5px;
        cursor: pointer;

        h6 {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        p {
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 这里是超出几行省略 */
            overflow: hidden;
        }
    }
    .modelActive {
        border: 1px solid ${theme.palette.primary.main};
    }
`;
