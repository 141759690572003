/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, Button } from '@mui/material';
import { Home } from 'components/home';
import { LoginCheck } from 'components/loginCheck';

export const HomePage = () => {
    return (
        <LoginCheck>
            <Home />
        </LoginCheck>
    );
};
