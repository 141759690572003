import { Metadata, Request } from 'grpc-web';
import { ChatServicePromiseClient } from 'proto/v1alpha1/chat_grpc_web_pb';
import { UserServicePromiseClient } from 'proto/v1alpha1/user_grpc_web_pb';

let apiEndpoint = `${window.location.protocol}//${window.location.host}`;
//TODO: change to env config
if (process.env.NODE_ENV === 'development') {
    apiEndpoint = 'http://localhost:7000';
}

let token: string | undefined;
export const setClientToken = (t: string) => {
    token = t;
};

const authIntercept = (request: Request<unknown, unknown>, invoker: any) => {
    if (token) {
        const meta: Metadata = request.getMetadata();
        meta['authorization'] = `Bearer ${token}`;
    }
    return invoker(request);
};

const clientOpts = {
    unaryInterceptors: [{ intercept: authIntercept }],
    streamInterceptors: [{ intercept: authIntercept }],
};

export const userClient = new UserServicePromiseClient(apiEndpoint, null, clientOpts);
export const chatClient = new ChatServicePromiseClient(apiEndpoint, null, clientOpts);
