/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box } from '@mui/material';
import { Loading } from 'components/ui/loading';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { login, selectUser, selectUserState } from 'modules/user';
import { RootPageContext } from 'pages/rootPage';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

export const LoginCheck = ({ force, children }: PropsWithChildren<{ force?: boolean }>) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [end, setEnd] = useState<boolean>(false);
    const { token } = useAppSelector(selectUserState);
    const { data: user } = useAppSelector(selectUser);

    const rootPageContext: RootPageContext = useOutletContext();

    useEffect(() => {
        if (user) {
            setEnd(true);
            return;
        }

        if (token !== '') {
            dispatch(login({ token })).then((res: any) => {
                if (res.error) {
                    if (force) {
                        rootPageContext.doLogin(() => {
                            setEnd(true);
                        });
                    }
                } else {
                    setEnd(true);
                }
            });
            return;
        } else {
            if (force) {
                rootPageContext.doLogin(() => {
                    setEnd(true);
                });
            }
        }
    }, [token, user]);

    return <Loading loaded={!force || end}>{children}</Loading>;
};
