/**  @jsxImportSource @emotion/react */
import { css, Theme, useTheme } from '@emotion/react';
import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/redux';
import { selectCurrConv, selectModels } from 'modules/chat';
import { Markdown } from 'components/ui/markdown/markdown';
import PersonIcon from '@mui/icons-material/Person';
import GrainIcon from '@mui/icons-material/Grain';
import { useEffect, useRef, useState } from 'react';
import * as _ from 'lodash';
import { ChatConversation } from 'proto/v1alpha1/chat_pb';

export const ChatContent = ({ conv, initScrollToBottom }: { conv: ChatConversation.AsObject; initScrollToBottom?: boolean }) => {
    const { data: models } = useAppSelector(selectModels);
    const [scrollToBottom, setScrollToBottom] = useState<boolean>(!!initScrollToBottom);
    const root = useRef<HTMLDivElement>();
    const scrollToDiv = useRef<HTMLDivElement>();

    const handleScroll = _.throttle(() => {
        if (scrollToDiv.current && root.current) {
            const curr = scrollToDiv.current.getBoundingClientRect();
            const parent = root.current.parentElement?.getBoundingClientRect();
            if (parent && curr) {
                setScrollToBottom(curr.top < parent.bottom && curr.bottom > parent.top);
            }
        }
    }, 1000);

    useEffect(() => {
        if (scrollToBottom && scrollToDiv.current) scrollToDiv.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, [conv]);

    return (
        <Box css={styles} ref={root} onWheel={handleScroll}>
            <Box className="container" sx={conv.modelsList.length < 2 ? { maxWidth: '1000px' } : {}}>
                {conv?.messagesList.map((m) => (
                    <>
                        <Box className="line">
                            <Box className="icon">
                                <PersonIcon />
                            </Box>
                            <Box className="messageBox">
                                <Box className="message messageUser">
                                    <Typography variant="body1">{m.prompt}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="line">
                            <Box className="icon">
                                <GrainIcon />
                            </Box>
                            <Box className="messageBox">
                                {m.completesList.map((c) => (
                                    <Box key={c.model} className="message messageComplete">
                                        <Box className="completeModel">
                                            <Typography variant="body2"> {models?.objs[c.model].name || c.model}</Typography>
                                        </Box>
                                        <Box className="completeContent">
                                            {c.message !== '' && (
                                                <Box className="completeMarkdown">
                                                    <Markdown value={c.message} />
                                                </Box>
                                            )}
                                            {c.error !== '' && (
                                                <Typography className="completeError" variant="body2" color="error.main">
                                                    {c.error}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </>
                ))}
            </Box>

            <Box sx={{ width: '100%', paddingBottom: '32px' }} ref={scrollToDiv}>
                <Box sx={{ height: '10px', width: '100%' }}> </Box>
            </Box>
        </Box>
    );
};

const styles = (theme: Theme) => css`
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > .container {
        width: 100%;

        & > .line {
            display: flex;
            margin-top: 23px;

            & > .icon {
                margin-right: 10px;
                svg {
                    fill: ${theme.c.colors.text.light};
                }
            }

            & > .messageBox {
                flex: 1;
                width: 100%;
                display: flex;
                overflow: auto;

                & > .message {
                    min-width: 210px;
                    /* max-width: 890px; */
                    margin: 0px 8px;
                    width: 100%;

                    & > .completeModel {
                        height: 28px;
                        p {
                            color: ${theme.c.colors.text.main};
                            font-weight: 300;
                        }
                    }

                    & > .completeContent {
                        height: calc(100% - 28px);
                        border: 1px solid ${theme.palette.divider};
                        padding: 12px 18px;
                        border-radius: 10px;
                        :hover {
                            border-color: ${theme.c.colors.primary.main};
                        }
                    }
                }

                & > .messageUser {
                    margin: 0px 8px;

                    p {
                        color: ${theme.c.colors.text.main};
                        font-weight: 300;
                    }
                }

                & > .messageComplete {
                }
            }
        }
    }

    /* & > div {
        max-width: 100%;
        /* min-width: 890px; 
    }

    .messageLine {
        display: flex;
        margin-top: 23px;
    }

    .icon {
        margin-right: 10px;
        svg {
            fill: ${theme.c.colors.text.light};
        }
    }

    .userMsg {
        margin: 0px 8px;
        min-width: 210px;
        max-width: 890px;
        p {
            color: ${theme.c.colors.text.main};
            font-weight: 300;
        }
    }

    .completeMsgs {
        display: flex;
        width: 100%;
        overflow-y: auto;
        padding-bottom: 10px;
    }

    .completeMsg {
        min-width: 210px;
        max-width: 890px;
        margin: 0px 8px;
        width: 100%;
    }

    .completeModel {
        height: 28px;
        p {
            color: ${theme.c.colors.text.main};
            font-weight: 300;
        }
    }

    .completeContent {
        width: 100%;
        height: calc(100% - 28px);
        border: 1px solid ${theme.palette.divider};
        padding: 12px 18px;
        border-radius: 10px;
        :hover {
            border-color: ${theme.c.colors.primary.main};
        }
    }

    .completeMarkdown {
        p {
            color: ${theme.c.colors.text.main};
            font-weight: 300;
        }
    }

    .completeError {
    } */
`;
