/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, Button, Divider, IconButton, MenuItem, MenuList, Popover, PopoverProps, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { logout, selectUser } from 'modules/user';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
export const Navbar = ({ onHide, NavMenu }: { onHide: () => void; NavMenu?: React.FC }) => {
    const { data: user } = useAppSelector(selectUser);
    const settingRef = useRef();
    const [openSetting, setOpenSetting] = useState<boolean>(false);

    const showEmail = useMemo(() => {
        if (!user || !user?.email) return '';

        let [userName, dom] = user.email.split('@');
        userName =
            userName.length <= 4
                ? '*'.repeat(userName.length)
                : userName.substring(0, 2) + '*'.repeat(userName.length - 4) + userName.substring(userName.length - 2);
        return userName + '@' + dom;
    }, [user]);

    return (
        <Box css={styles.navRoot} sx={{ width: '320px' }}>
            <Box className="top">
                <IconButton onClick={onHide}>
                    <MenuIcon />
                </IconButton>
                <Link to="/">
                    <Typography variant="subtitle1">章鱼AI</Typography>
                </Link>
            </Box>
            <Box css={styles.navContent}>
                <Box> {NavMenu && <NavMenu />}</Box>
                <Box className="setting">
                    <Box className="settingItem" ref={settingRef} onClick={() => setOpenSetting(true)}>
                        <AccountCircleIcon />
                        <Typography variant="body2">{showEmail}</Typography>
                    </Box>
                </Box>
            </Box>
            <SettingMenu
                anchorEl={settingRef.current}
                open={openSetting}
                onClose={() => {
                    setOpenSetting(false);
                }}
            />
        </Box>
    );
};

const SettingMenu = (props: Pick<PopoverProps, 'anchorEl' | 'open' | 'onClose'>) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { data: user } = useAppSelector(selectUser);
    const doLogout = () => {
        dispatch(logout());
        navigate('/');
    };

    return (
        <Popover {...props}>
            {user && (
                <Box css={styles.menuRoot}>
                    <Box className="user">
                        <Typography>{user.email}</Typography>
                        {user.isVip && (
                            <Box className="vip">
                                <Typography variant="body2" fontWeight={500} color="primary">
                                    VIP用户
                                </Typography>
                                <Typography variant="body2">({user.vipExpiredAtStr})</Typography>
                            </Box>
                        )}
                    </Box>
                    <MenuList>
                        {/* <MenuItem>设置</MenuItem> */}
                        {/* <Divider /> */}
                        <MenuItem onClick={doLogout}>退出登录</MenuItem>
                    </MenuList>
                </Box>
            )}
        </Popover>
    );
};

const styles = {
    navRoot: (theme: Theme) => css`
        height: 100%;
        width: ${theme.c.sizes.navbarWidth}px;
        .top {
            color: ${theme.c.colors.text.dark};
            padding: 0px 16px;
            height: ${theme.c.sizes?.topbarHeight}px;
            display: flex;
            align-items: center;

            svg {
                fill: ${theme.c.colors.text.light};
            }
        }
    `,

    navContent: (theme: Theme) => css`
        height: calc(100% - ${theme.c.sizes?.topbarHeight}px);
        display: flex;
        flex-direction: column;
        & > div:nth-of-type(1) {
            flex: 1;
            height: 100%;
            overflow-y: auto;
        }
        & > div:nth-of-type(2) {
            padding: 16px 8px;

            .settingItem {
                display: flex;
                align-items: center;
                cursor: pointer;
                svg {
                    height: 24px;
                    width: 24px;
                    margin-right: 8px;
                }
            }
        }

        .setting {
            border-top: 1px solid ${theme.palette.divider};
            margin-left: 16px;

            .settingItem {
                & > p {
                    white-space: nowrap;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    `,

    menuRoot: (theme: Theme) => css`
        .user {
            padding: 16px;
            border-bottom: 1px solid ${theme.palette.divider};

            .vip {
                display: flex;
                padding-top: 5px;
            }
        }
    `,
};
