/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, Divider, IconButton, MenuItem, MenuList, Popover, TextField, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { deleteConversion, selectCurrConv, updateConversationTitle } from 'modules/chat';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as ExportExcel from 'js-export-excel';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { ShareCreateDlg } from 'components/shareCreateDlg';

export const ChatTitle = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { data: conv } = useAppSelector(selectCurrConv);
    const menuRef = useRef<HTMLButtonElement>();
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [editing, setEditing] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [shareing, setShareing] = useState<boolean>(false);

    useEffect(() => {
        if (conv) {
            setTitle(conv.title);
        }
    }, [conv]);

    const handleTitleClick = () => {
        if (conv?.id) setEditing(true);
    };

    const handleTitleChange = (e: any) => {
        setTitle(e.target.value);
    };

    const handleTitleBlur = () => {
        setEditing(false);
        if (conv && conv.title !== title) dispatch(updateConversationTitle({ id: conv.id, title: title }));
    };

    const handleMenuClose = () => {
        setOpenMenu(false);
        setDeleting(false);
    };

    const handleDelete = () => {
        if (!conv) return;

        if (!deleting) {
            setDeleting(true);
            return;
        }

        dispatch(deleteConversion({ id: conv.id })).then((res: any) => {
            if (!res.error) {
                navigate('/chat', { replace: true });
            }
        });
    };

    const exportExcel = () => {
        if (!conv) return;
        new ExportExcel({
            fileName: `章鱼AI-${conv.title !== '' ? conv.title : '未命名对话'}`,
            datas: [
                {
                    sheetName: '消息',
                    sheetHeader: ['用户消息', ...conv.modelsList],
                    sheetFilter: ['prompt', ...conv.modelsList],
                    sheetData: conv.messagesList.map((msg) => {
                        const line: any = {
                            prompt: msg.prompt,
                        };
                        for (let model of conv.modelsList) {
                            const c = msg.completesList.find((c) => c.model === model);
                            line[model] = c ? c.message : '';
                        }
                        return line;
                    }),
                },
            ],
        }).saveExcel();
    };

    return (
        <Box css={styles.root}>
            <Box></Box>
            <Box>
                {!editing ? (
                    <Typography className="title" onClick={handleTitleClick} variant="body1">
                        {conv?.id === '' ? '新建对话' : title === '' ? '未命名对话' : title}
                    </Typography>
                ) : (
                    <TextField
                        fullWidth
                        autoFocus
                        variant="standard"
                        size="small"
                        value={title}
                        onChange={handleTitleChange}
                        onBlur={handleTitleBlur}
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                handleTitleBlur();
                            }
                        }}
                    />
                )}
            </Box>
            <Box>
                {conv && conv.id && (
                    <>
                        <IconButton size="small" onClick={() => setShareing(true)}>
                            <ShareOutlinedIcon fontSize="small" />
                        </IconButton>
                        <Box ref={menuRef}>
                            <IconButton size="small" onClick={() => setOpenMenu(true)}>
                                <MoreHorizOutlinedIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </>
                )}
            </Box>

            {openMenu && (
                <Popover open={openMenu} anchorEl={menuRef.current} onClose={handleMenuClose}>
                    <Box css={styles.menuRoot}>
                        <MenuList>
                            <MenuItem onClick={exportExcel}>
                                <TableChartOutlinedIcon />
                                <Typography variant="body2">导出Excel</Typography>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={handleDelete} className="delete">
                                <DeleteOutlineIcon />
                                <Typography variant="body2">{deleting ? '确认删除？' : '删除'}</Typography>
                            </MenuItem>
                        </MenuList>
                    </Box>
                </Popover>
            )}

            {shareing && <ShareCreateDlg open={shareing} title="分享对话" shareChat={conv?.id ?? ''} onClose={() => setShareing(false)} />}
        </Box>
    );
};

const styles = {
    root: (theme: Theme) => css`
        padding: 8px;
        height: ${theme.c.sizes?.topbarHeight}px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div:nth-of-type(1) {
            width: 25%;
            overflow-y: auto;
            display: flex;
            justify-content: start;
        }

        & > :nth-of-type(2) {
            width: 50%;
            overflow-y: auto;
            display: flex;
            justify-content: center;
        }

        & > :nth-of-type(3) {
            width: 25%;
            overflow-y: auto;
            display: flex;
            justify-content: end;
            align-items: center;
            padding-right: 10px;

            button {
                margin: 0px 3px;
            }
            svg {
                height: 18px;
                width: 18px;
            }
        }

        .title {
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    `,
    menuRoot: (theme: Theme) => css`
        svg {
            width: 20px;
            margin-right: 5px;
        }

        .delete {
            color: ${theme.palette.error.main};
            svg {
                fill: ${theme.palette.error.main};
            }
        }
    `,
};
