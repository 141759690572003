/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { LoginForm } from 'components/loginForm';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { RootPageContext } from 'pages/rootPage';

export interface LoginDlgRef extends Pick<RootPageContext, 'doLogin'> {}

export const LoginDlg = forwardRef(({}: {}, ref) => {
    const [open, setOpen] = useState<boolean>(false);
    const callback = useRef<() => void>();
    const loginSuccess = useRef<boolean>(false);

    useImperativeHandle(
        ref,
        (): LoginDlgRef => ({
            doLogin: (success: () => void) => {
                callback.current = success;
                setOpen(true);
            },
        }),
        []
    );

    const onLoginSuccess = () => {
        loginSuccess.current = true;
    };
    const close = () => {
        if (callback.current && loginSuccess.current) {
            callback.current();
        }
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={(e, reason) => close}>
            <Box css={styles}>
                <Box>
                    <Typography variant="subtitle1">登录</Typography>
                    <IconButton onClick={close}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>

                <LoginForm onSuccess={onLoginSuccess} onEnd={close} />
            </Box>
        </Dialog>
    );
});

const styles = (theme: Theme) => css`
    & > :nth-of-type(1) {
        padding: 20px 18px 18px 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
            color: ${theme.palette.text.disabled};
            height: 16px;
            width: 16px;
        }
    }

    & > :nth-of-type(2) {
        padding: 0px 23px 25px 23px;
    }
`;
