/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, Fab, IconButton } from '@mui/material';
import { LoginCheck } from 'components/loginCheck';
import { Navbar } from 'components/navbar';
import { Loading } from 'components/ui/loading';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { login, selectUser, selectUserState } from 'modules/user';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const navbarWidth = 256;

export const Layout = ({
    children,
    NavMenu,
}: PropsWithChildren<{
    NavMenu?: React.FC;
}>) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [hide, setHide] = useState<boolean>(false);

    return (
        <LoginCheck>
            <Box css={styles.main}>
                <Box
                    css={styles.navbar}
                    sx={{
                        display: `${hide ? 'none' : 'unset'}`,
                        left: 0,
                        width: `${hide ? 0 : navbarWidth}px`,
                        top: 0,
                        bottom: 0,
                    }}
                >
                    <Navbar onHide={() => setHide(true)} NavMenu={NavMenu} />
                </Box>
                {hide && (
                    <Box css={styles.floatButton}>
                        <IconButton onClick={() => setHide(false)}>
                            <MenuIcon />
                        </IconButton>
                    </Box>
                )}
                <Box
                    css={styles.content}
                    sx={{
                        left: `${hide ? 0 : navbarWidth}px`,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </LoginCheck>
    );
};

const styles = {
    main: (theme: Theme) => css`
        height: 100%;
        width: 100%;
        background-color: ${theme.c.colors.background.dark};
    `,
    navbar: (theme: Theme) => css`
        position: absolute;
    `,
    content: (theme: Theme) => css`
        position: absolute;
    `,
    floatButton: (theme: Theme) => css`
        position: absolute;
        padding: 8px 16px;
        height: ${theme.c.sizes?.topbarHeight}px;
        z-index: ${theme.c.zIndexs?.navbar};
        cursor: pointer;
    `,
};
