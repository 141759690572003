/**  @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react';
import { Box } from '@mui/material';
import React from 'react';
import { PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { HashLink } from 'react-router-hash-link';
import remarkBreaks from 'remark-breaks';
import { CodeRenderer } from './codeRender';

export interface MarkdownProps {
    value: string;
}

export const Markdown = ({ value }: MarkdownProps) => {
    return (
        <Box css={c}>
            <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkBreaks]}
                components={{
                    h1: HeadingRenderer,
                    h2: HeadingRenderer,
                    h3: HeadingRenderer,
                    h4: HeadingRenderer,
                    h5: HeadingRenderer,
                    h6: HeadingRenderer,
                    a: LinkRenderer,
                    table: TableRenderer,
                    code: CodeRenderer,
                }}
                skipHtml={true}
            >
                {value}
            </ReactMarkdown>
        </Box>
    );
};

function flatten(text: string, child: any): any {
    return typeof child === 'string' ? text + child : React.Children.toArray(child.props.children).reduce(flatten, text);
}

const HeadingRenderer = (props: any) => {
    const children = React.Children.toArray(props.children);
    const text = children.reduce(flatten, '');
    const slug = text
        .toLowerCase()
        .replace(/[^a-zA-Z0-9_\s]/g, '') // remove punctuation
        .replace(/\s/g, '-'); // replace spaces with dash
    return React.createElement('h' + props.level, { id: slug }, props.children);
};

const LinkRenderer = (props: any) => {
    if (props.href.startsWith('#')) {
        return <HashLink to={props.href}>{props.children}</HashLink>;
    }
    // If it's not a hash link it's an external link since it's rendering
    // the package README. Because of that, render it as a normal anchor
    return <a href={props.href}>{props.children}</a>;
};

const TableRenderer = (props: any) => {
    return <table css={tableStyle}>{props.children}</table>;
};

const c = (theme: Theme) => css`
    color: ${theme.c.colors.text.main};
    p {
        font-size: 1rem;
    }
    p:first-of-type {
        margin-top: 0;
    }

    pre {
        overflow: auto;
        padding: 0.6rem;
        border-radius: 5px;
        margin: 0.625em 0;
        border: solid 1px #ccc;
    }

    ul {
        margin-top: 0.6rem;

        li {
            p {
                display: inline;
            }
        }
    }

    blockquote {
        max-width: 100%;
        padding: 0.1em 1em;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        margin: 1em 0;
        background-color: #fff;

        p:first-of-type {
            margin-top: 0.6rem;
        }

        p:last-child {
            margin-bottom: 0.6rem;
        }
    }

    blockquote::before,
    blockquote::after {
        background-image: none;
    }

    img {
        max-width: 100%;
    }
`;

const tableStyle = css`
    border-collapse: separate;
    border: 1px solid #ccc;
    border-radius: 0.125rem;
    background-color: #fff;
    color: #565656;
    margin: 0;
    margin-top: 1rem;
    max-width: 100%;
    width: 100%;

    th,
    td {
        font-size: 0.541667rem;
        line-height: 0.5833334rem;
        border-top: 1px solid #eee;
        padding: 0.4583333333rem 0.5rem 0.4583333333rem;
        text-align: center;
        vertical-align: top;
    }
    th.left,
    td.left {
        text-align: left;
    }
    th.left:first-of-type,
    td.left:first-of-type {
        padding-left: 0.25rem;
    }
    th {
        color: #565656;
        font-size: 0.458333rem;
        font-weight: 600;
        letter-spacing: 0.03em;
        background-color: #fafafa;
        vertical-align: bottom;
        border-bottom: 1px solid #ccc;
        border-top: 0 none;
    }
    tbody tr:first-of-type td {
        border-top: 0 none;
    }
    tbody + tbody {
        border-top: 1px solid #ccc;
    }
    thead th:first-of-type {
        border-radius: calc(0.125rem - 1px) 0 0 0;
    }
    thead th:last-child {
        border-radius: 0 calc(0.125rem - 1px) 0 0;
    }
    tbody:last-child tr:last-child td:first-of-type {
        border-radius: 0 0 0 calc(0.125rem - 1px);
    }
    tbody:last-child tr:last-child td:last-child {
        border-radius: 0 0 calc(0.125rem - 1px) 0;
    }

    -compact th,
    -compact td {
        padding-top: calc(0.2083333rem + 1px);
        padding-bottom: calc(0.2083333rem);
    }

    .table-vertical thead th {
        border: 0 none;
        border-radius: 0;
        display: none;
    }
    .table-vertical th {
        border-bottom: 0;
        border-top: 1px solid #ccc;
        vertical-align: top;
    }
    .table-vertical td,
    .table-vertical th {
        text-align: left;
        border-color: #ccc;
    }
    .table-vertical td:first-of-type,
    .table-vertical th:first-of-type {
        border-right: 1px solid #ccc;
        background-color: #fafafa;
        font-weight: 600;
    }
    .table-vertical tbody:first-of-type tr:first-of-type th,
    .table-vertical tbody:first-of-type tr:first-of-type td {
        border-top: 0 none;
    }
    .table-vertical tbody:first-of-type tr:first-of-type th:first-of-type,
    .table-vertical tbody:first-of-type tr:first-of-type td:first-of-type {
        border-radius: calc(0.125rem - 1px) 0 0 0;
    }
    .table-vertical tbody:first-of-type tr:first-of-type th:last-child,
    .table-vertical tbody:first-of-type tr:first-of-type td:last-child {
        border-radius: 0 calc(0.125rem - 1px) 0 0;
    }
    .table-vertical tbody:last-child tr:last-child th:first-of-type,
    .table-vertical tbody:last-child tr:last-child td:first-of-type {
        border-radius: 0 0 0 calc(0.125rem - 1px);
    }
    .table-vertical tbody:last-child tr:last-child th:last-child,
    .table-vertical tbody:last-child tr:last-child td:last-child {
        border-radius: 0 0 calc(0.125rem - 1px) 0;
    }

    .table-noborder {
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: transparent;
        border: 0;
    }
    .table-noborder th {
        background-color: transparent;
        border-bottom-color: #ddd;
        border-top: 0 none;
    }
    .table-noborder th:first-of-type {
        border-right: 0 none;
    }
    .table-noborder td {
        border-top: 0 none;
        padding-top: calc(0.4583333333rem + 1px);
    }
    .table-noborder td:first-of-type {
        border-right: 0 none;
    }
    .table-noborder thead th:first-of-type,
    .table-noborder thead th:last-child {
        border-radius: 0;
    }
    .table-noborder th,
    .table-noborder td {
        border-radius: 0 !important;
    }
    .table-noborder th:first-of-type,
    .table-noborder td:first-of-type {
        padding-left: 0;
    }

    .table-compact th,
    .table-compact td {
        padding-top: calc(0.2083333rem + 1px);
        padding-bottom: 0.2083333rem;
    }
    .table-compact.table-noborder th,
    .table-compact.table-noborder td {
        padding-top: calc(0.2083333rem + 2px);
        padding-bottom: calc(0.2083333rem + 1px);
    }
`;
