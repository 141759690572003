import { Theme } from '@emotion/react';
import { baseTheme, createMuiTheme } from 'components/theme/base';
import { CustomTheme } from 'components/theme/theme';

// const lightCustomTheme: CustomTheme = {
//     ...baseTheme,
//     colors: {
//         primary: {
//             main: '#0C66E4',
//             light: '#E6EFFC',
//             dark: '#0052CC',
//         },
//         background: {
//             light: 'rgba(32,33,36,0.059)',
//             main: '#fff',
//             dark: '#f6f8fc',
//         },
//         text: {
//             light: '#666',
//             main: '#202124',
//             dark: '#001d35',
//         },
//     },
// };

const lightCustomTheme: CustomTheme = {
    ...baseTheme,
    colors: {
        primary: {
            main: '#219F94',
            light: '#d8f6f4',
            dark: '#047c74',
        },
        background: {
            light: 'rgba(32,33,36,0.059)',
            main: '#fff',
            dark: '#f6f8fc',
        },
        text: {
            light: '#666',
            main: '#202124',
            dark: '#001d35',
        },
    },
};

export const lightTheme: Theme = {
    ...createMuiTheme(lightCustomTheme),
    c: lightCustomTheme,
};
