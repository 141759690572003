/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, CircularProgress, Container, FormControl, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { addConvMessage, selectCurrConv } from 'modules/chat';
import { chatClient } from 'api/client';
import { ChatRequest, ChatResponse } from 'proto/v1alpha1/chat_pb';
import { RpcError } from 'grpc-web';
import { useNavigate } from 'react-router-dom';

export const ChatInput = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { data: conv } = useAppSelector(selectCurrConv);
    const [text, setText] = useState<string>('');
    const [isComposition, setComposition] = useState<boolean>(false);
    const textRef = useRef<any>();

    const [error, setError] = useState<string>('');
    const [sending, setSending] = useState<boolean>(false);

    const send = () => {
        if (!conv) return;
        if (sending) return;

        const t = text.trim();
        if (t === '') return;

        setError('');
        setSending(true);
        let lastRsp: ChatResponse;
        const stream = chatClient.chat(new ChatRequest().setConversationId(conv.id).setModelsList(conv.modelsList).setPrompt(t));
        stream.on('data', (rsp: ChatResponse) => {
            lastRsp = rsp;
            setText('');
            dispatch(addConvMessage(rsp.toObject()));
        });
        stream.on('error', (err: RpcError) => {
            if (conv.id === '' && lastRsp) {
                navigate(`/chat/${lastRsp.getMessage()?.getConversationId()}`, { replace: true });
            } else {
                setError(err.message);
                setSending(false);
            }
        });
        stream.on('end', () => {
            console.log('end');
            if (conv.id === '' && lastRsp) {
                navigate(`/chat/${lastRsp.getMessage()?.getConversationId()}`, { replace: true });
            } else {
                setSending(false);
            }
        });
    };

    const enable = conv && conv.modelsList.length > 0 && !sending;

    return (
        <Stack css={styles} justifyContent="end" alignItems="center">
            <Box sx={{ height: '32px', textAlign: 'center' }}>
                <Typography variant="body2" color="error.main">
                    {error}
                </Typography>
            </Box>
            <FormControl className="input" fullWidth>
                <OutlinedInput
                    fullWidth
                    multiline
                    autoFocus
                    maxRows={10}
                    value={text}
                    inputRef={textRef}
                    onChange={(e) => setText(e.target.value)}
                    onCompositionStart={() => setComposition(true)}
                    onCompositionEnd={() => setComposition(false)}
                    onKeyDown={(e) => {
                        if (isComposition) return;
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            if (e.ctrlKey || e.metaKey || e.altKey) {
                                if (textRef.current) {
                                    const cursorPos = textRef.current.selectionStart;
                                    const newText = text.slice(0, cursorPos) + '\n' + text.slice(cursorPos);
                                    setText(newText);
                                    setTimeout(() => {
                                        textRef.current.selectionStart = cursorPos + 1;
                                        textRef.current.selectionEnd = cursorPos + 1;
                                    }, 0);
                                }
                            } else {
                                send();
                            }
                        }
                    }}
                    disabled={!enable}
                    placeholder={`${conv?.modelsList.length === 0 ? '选择模型开始对话' : '输入内容...'}`}
                    endAdornment={
                        <InputAdornment position="end">
                            {sending ? (
                                <CircularProgress size={15} color="inherit" />
                            ) : (
                                <IconButton disabled={!enable} color="primary" edge="end" onClick={send}>
                                    <SendIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Stack>
    );
};

const styles = (theme: Theme) => css`
    padding: 16px 32px 0px 32px;
    width: 100%;

    .input {
        max-width: 850px;
    }
`;
