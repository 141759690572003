import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { chatClient, setClientToken, userClient } from 'api/client';
import { ChatConversation, GetConversationRequest, GetConversationsRequest } from 'proto/v1alpha1/chat_pb';
import { LoginRequest, LogoutRequest, LogoutResponse, UserInfo } from 'proto/v1alpha1/user_pb';
import { LoadStatus, WithLoadStatus } from 'utils/common';

const MODULE_NAME = 'user';
const LOCAL_STORAGE_KEY_TOKEN = process.env.NODE_ENV === 'development' ? '_zy_dev_token' : '_zy_token';

export const login = createAsyncThunk<UserInfo.AsObject, { emailOrPhone?: string; password?: string; token?: string; code?: string }>(
    `${MODULE_NAME}/login`,
    async (args) => {
        return (
            await userClient.login(
                new LoginRequest()
                    .setPhoneOrEmail(args.emailOrPhone ?? '')
                    .setPassword(args.password ?? '')
                    .setToken(args.token ?? '')
                    .setCode(args.code ?? '')
            )
        ).toObject().user as UserInfo.AsObject;
    }
);

export const logout = createAsyncThunk<LogoutResponse.AsObject, void>(`${MODULE_NAME}/logout`, async (args) => {
    return (await userClient.logout(new LogoutRequest())).toObject();
});

export interface UserState {
    token: string;
    user: WithLoadStatus<UserInfo.AsObject>;
}

const initialState: UserState = {
    token: localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) ?? '',
    user: {},
};

export const userSlice = createSlice({
    name: MODULE_NAME,
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(login.pending, (state, action) => {
                state.user = { loading: true };
            })
            .addCase(login.fulfilled, (state, action) => {
                localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, action.payload.token);
                setClientToken(action.payload.token);
                state.token = action.payload.token;
                state.user = { loading: false, data: action.payload };
            })
            .addCase(login.rejected, (state, action) => {
                state.user = { loading: false, loadErr: action.error.message };
            })
            .addCase(logout.pending, (state, action) => {
                localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);
                state.token = '';
                state.user = {};
            });
    },
});
