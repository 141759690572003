/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box } from '@mui/material';
import { ChatInput } from 'components/chatInput';
import { PropsWithChildren } from 'react';

export const ChatContainer = ({ intpuTip, children }: PropsWithChildren<{ intpuTip?: string }>) => {
    return (
        <Box css={styles}>
            <Box>{children}</Box>
            <ChatInput />
        </Box>
    );
};

const styles = (theme: Theme) => css`
    height: calc(100% - ${theme.c.sizes?.topbarHeight + 16}px);
    margin: 0 16px;
    padding: 0 32px 16px 32px;
    background-color: ${theme.palette.background.default};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */

    & > div:nth-of-type(1) {
        flex: 1;
        overflow: auto;
    }
`;
