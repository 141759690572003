/**  @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ModelList } from 'components/modeList';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { selectCurrConv, selectModels, setConvModels } from 'modules/chat';

export const ChooseModel = () => {
    const dispatch = useAppDispatch();
    const { data: conv } = useAppSelector(selectCurrConv);
    const onModelChoose = (models: string[]) => {
        dispatch(setConvModels(models));
    };

    return (
        <Box css={styles}>
            <Box>
                <Box className="title">
                    <Typography variant="body1">选择您要对话的模型</Typography>
                    <Typography variant="caption" color="text.secondary">
                        可选择多个模型，同时展开对话
                    </Typography>
                </Box>
                <ModelList models={conv?.modelsList || []} onModelChoose={onModelChoose} />
            </Box>
        </Box>
    );
};

const styles = (theme: Theme) => css`
    /* max-width: 640px; */
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
        max-width: 900px;
    }

    .title {
        width: 100%;
        padding: 40px 0px 20px 0px;

        text-align: center;
        /* border-bottom: 1px solid ${theme.palette.divider}; */
    }
`;
